import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

function GetAQuote() {

  // Temporarily hide page
  useEffect(() => {
    navigate('/')
  }, [])

  return (
    <Layout>
      <SEO
        title="Get A Quote"
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
      />

      <section>
        <p>Get A Quote</p>
      </section>
    </Layout>
  )
}

export default GetAQuote
